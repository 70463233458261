


























import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import LessonCommonView from '@/components/views/lesson/LessonCommonView.vue'
import LessonVideoCurtain from '@/components/views/lesson/LessonVideoCurtain.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'
// store
import MasterLessonsModule from '@/store/modules/master/lessons'
// interfaces
import {
  AdjacentLessonResource,
  LessonLargeResource,
} from '@/store/types'

@Component({
  components: {
    LessonCommonView,
    LessonVideoCurtain,
  },
})
export default class LessonItem extends Mixins(MasterGroupMixin, CurrentTimeMixin) {
  private lesson: LessonLargeResource | null = null
  private rating: number | null = null
  private nextLesson: AdjacentLessonResource | null = null
  private prevLesson: AdjacentLessonResource | null = null
  private isLessonChecked = false

  private get lessonID () {
    return +this.$route.params.lessonID
  }

  private get breadcrumbs () {
    return [
      { name: 'Курсы', path: '/master/courses/my' },
      { name: 'Уроки', path: '/master/lessons' },
    ]
  }

  private get saveTimecode() {
    return MasterLessonsModule.lesson?.playbackMarker || 0
  }

  private mounted () {
    this.syncMasterGroup()
      .then(this.fetchLesson)
  }

  private fetchLesson () {
    MasterLessonsModule.fetchLesson({
      lessonID: this.lessonID,
      masterGroupID: this.currentMasterGroupID,
    })
      .then(response => {
        this.lesson = response.lesson
        this.rating = response.rating
        this.nextLesson = response.next
        this.prevLesson = response.previous
        this.isLessonChecked = Boolean(response.checkedAt)

        // Костыль для того, чтобы в случае, когда разные качества видео возвращают 404ю,
        // самое высокое качество заменялось оригиналом
        if (this.lesson.video && this.lesson.video.formats && this.lesson.video.formats.length) {
          this.lesson.video.formats[this.lesson.video.formats.length - 1] = {
            id: this.lesson.video.id,
            label: this.lesson.video.formats[this.lesson.video.formats.length - 1].label,
            quality: this.lesson.video.formats[this.lesson.video.formats.length - 1].quality,
            size: this.lesson.video.size,
            url: this.lesson.video.url,
          }
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'master.lessons' })
      })
  }

  @Watch('lessonID')
  private watchLessonID() {
    this.fetchLesson()
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.lesson ? this.lesson.title : 'Урок',
    }
  }
}
